exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cashback-deal-provider-jsx": () => import("./../../../src/pages/cashback/{Deal.provider}.jsx" /* webpackChunkName: "component---src-pages-cashback-deal-provider-jsx" */),
  "component---src-pages-cashback-deal-slug-jsx": () => import("./../../../src/pages/cashback/{Deal.slug}.jsx" /* webpackChunkName: "component---src-pages-cashback-deal-slug-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-affiliate-link-disclosure-js": () => import("./../../../src/pages/legal/affiliate-link-disclosure.js" /* webpackChunkName: "component---src-pages-legal-affiliate-link-disclosure-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-website-disclaimer-js": () => import("./../../../src/pages/legal/website-disclaimer.js" /* webpackChunkName: "component---src-pages-legal-website-disclaimer-js" */),
  "component---src-pages-legal-website-terms-of-use-js": () => import("./../../../src/pages/legal/website-terms-of-use.js" /* webpackChunkName: "component---src-pages-legal-website-terms-of-use-js" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

